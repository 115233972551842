.about
	display block
	&__title
		margin-bottom 5.5rem
		+below($sm)
			font-size 2.8rem
			line-height 1.25
			margin-bottom 3rem
	&__lable
		font-size 2rem
		line-height 1.75
		font-weight 700
		margin-bottom 1.5rem
	&__text
		font-size 2rem
		line-height 1.75
		margin-bottom 4.3rem

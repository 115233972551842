.reviews
	display block
	&__wrapper
		+below($sm)
			padding 0
	&__item
		margin-bottom 11rem
		+below($sm)
			margin-bottom 7rem
		&:last-child
			margin-bottom 0
	&__name
		font-size 3.2rem
		line-height 1.25
		font-weight 700
		margin-bottom 1.8rem
		+below($sm)
			font-size 2.8rem
			margin-bottom .6rem
			padding 0 1.5rem
	&__rating
		display inline-block
		margin 0 -8px
		margin-bottom 3rem
		+below($sm)
			padding 0 1.5rem
			margin-bottom 2rem
		svg
			width 20px
			height 20px
			margin 0 8px
			+below($sm)
				width 15px
				height 15px
		&_1
			svg
				&:first-child
					fill $blue
		&_2
			svg
				&:nth-child(-n + 2)
					fill $blue
		&_3
			svg
				&:nth-child(-n + 3)
					fill $blue
		&_4
			svg
				&:nth-child(-n + 4)
					fill $blue
		&_5
			svg
				fill $blue
	&__image
		width 100%
		height auto
		max-height 400px
		margin-bottom 4.7rem
		+below($sm)
			margin-bottom 3rem
		img
			display block
			width 100%
			height auto
			object-fit cover
	&__text
		position relative
		font-size 2rem
		line-height 1.75
		color $b
		+below($sm)
			padding 0 1.5rem
			line-height 1.5
		&:before
			position absolute
			content ''
			left 0
			bottom 0
			width 100%
			height 170px
			background linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #FFFFFF 100%)
		&_open
			&:before
				opacity 0
	&__button-wrapper
		margin-top 1rem
		text-align center
	&__toogle
		position relative
		font-size 2.8rem
		line-height 1.25
		color $blue
		font-weight 700
		padding-bottom 6px
		+below($sm)
			font-size 2.4rem
			line-height 1.46
		&:before
			position absolute
			content ''
			width 100%
			height 3px
			background-color $blue
			left 0
			bottom 0

.nf
	display block
	position relative
	background-color $blue
	height calc(100vh - 120px)
	overflow hidden
	+below($sm)
		height 100%
	&__bg
		top 0
		right 0
		position absolute
		height 100%
	&__container
		position relative
		display flex
		height 100%
		align-items center
		padding 5rem 1.5rem
	&__wrapper
		display flex
		+below($sm)
			flex-direction column-reverse
			align-items center
	&__text
		padding-top 9rem
		+below($sm)
			padding-top 5rem
	&__title
		color $w
		margin-bottom 4.5rem
		&_top
			margin-bottom 3.3rem
	&__image-block
		+below($sm)
			width 200px
		svg
			+below($sm)
				width 100%
				height auto

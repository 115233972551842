.popup
	display none
	width 100%
	.form
		padding-top 40px
	.fancybox-button
		width 58px
		height 58px
		svg
			width 40px
			height 40px

.popup.fancybox-content
		background $blue
		+below($sm)
			padding 0

.refs
	display block
	&__item
		border-top 2px solid $g
	&__href
		padding 6.4rem 0
		display flex
		color $b
		text-decoration none
		align-items center
		+below($sm)
			padding 5.9rem 0
		&:hover
			color $blue
			img
				opacity 0
				&:last-child
					opacity 1
			.refs__arrow
				stroke $blue
	&__image-block
		display block
		position relative
		height 50px
		width 75px
		margin-right 4.5rem
		img
			opacity 1
			transition .3s
			&:last-child
				position absolute
				top 0
				left 0
				opacity 0
	&__text-block
		display flex
		justify-content space-between
		width 100%
		align-items center
	&__arrow
		width 57px
		height 16px
		stroke $dg
		transition .3s
		+below($sm)
			display none

.contacts
	display block
	&__information
		width 50%
		padding-right 10rem
		padding-top 10rem
		padding-bottom 5rem
		+below($sm)
			width 100%
			padding-right 0
			padding-top 20rem
			padding-bottom 1.7rem
	&__title
		margin-bottom 10rem
		+below($sm)
			margin-bottom 7.8rem
	&__linkwrapper
		display flex
		flex-direction column
		margin-bottom 3rem
		+below($sm)
			margin-bottom 7.5rem
	&__value
		color $b
		font-size 2.8rem
		line-height 1.25
		text-decoration none
		padding-bottom 2.5rem
		+below($sm)
			padding-bottom 2rem
	&__textwrapper
		margin-bottom 5.5rem
		+below($sm)
			margin-bottom 7.3rem
	&__subtitle
		font-size 2rem
		line-height 2
		font-weight 700
		margin-bottom 1rem
	&__content
		p
			font-size 2rem
			line-height 1.5
	&__map-block
		z-index 4
		right 0
		top 0
		position fixed
		height 100vh
		width 50vw
		+below($sm)
			display none
.map
	width 100%
	height 100%

.pagehead
	position relative
	display block
	background-color $blue
	max-height 412px
	overflow hidden
	&__bg
		display block
		margin-left auto
		max-width 128rem
		height 412px
		+below($sm)
			height 257px
	&__image-box
		position absolute
		top 7rem
		left 50%
		transform translateX(-50%)
		+below($sm)
			top 5rem
			max-width 120px
			img
				width 100%
				object-fit scale-down


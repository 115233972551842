.header
	position fixed
	display block
	padding-top 4.5rem
	padding-bottom 2.5rem
	width 100%
	z-index 40
	background-color transparent
	top 0
	transition backgroud-color .3s
	+below($sm)
		padding-top 1.7rem
	&__wrapper
		display flex
		justify-content space-between
		align-items center
	&__menu
		background transparent
		position relative
		font-size 2.4rem
		line-height 1
		font-weight 700
		color $blue
		padding-bottom 8px
		&:before
			position absolute
			content ''
			width 100%
			height 4px
			background-color $blue
			left 0
			bottom 0
			opacity 0
			transition .3s
		&:hover
			&:before
				opacity 1
	&__category
		display flex
		margin 0 5rem 8px auto
		+below($sm)
			display none
	&__href
		position relative
		color $b
		text-decoration none
		font-size 2.4rem
		line-height 1
		font-weight 700
		text-transform lowercase
		padding-bottom 8px
		margin 0 1.5rem
		&:before
			position absolute
			content ''
			width 100%
			height 4px
			background-color $blue
			left 0
			bottom -2px
			opacity 0
			transition .3s
		&:hover
			&:before
				opacity 1

.list
	display block
	&_with-arrow
		.list__box
			&::after
				position absolute
				content ''
				width 12px
				height 12px
				border-left 4px solid $blue
				border-bottom 4px solid $blue
				right -7.3rem
				top 10rem
				transform rotate(-135deg)
				transition .3s
				+below($sm)
					display none
	&__item
		border-top 2px solid $g
		padding 7.5rem 0
		min-height 35rem
		+below($sm)
			padding 5.5rem 0 4.6rem
	&__box
		cursor pointer
		position relative
		display flex
		z-index 10
		text-decoration none
		+below($sm)
			flex-direction column
		&_nolink
			&::after
				position absolute
				content ''
				width 12px
				height 12px
				border-left 4px solid $blue
				border-bottom 4px solid $blue
				right -7.3rem
				top 10rem
				transform rotate(-45deg)
				transition .3s
				+below($sm)
					left calc(50% - 6px)
					right auto
					bottom 0
					top auto
		&:hover
			+above($sm)
				.list__title
					color $blue
				.list__image
					opacity 0
				.list__image.list__image_hover
						opacity 1
				.list__description
					color $blue
		&_open
			.list__image
				opacity 0
			.list__image.list__image_hover
					opacity 1
			.list__preview
				display none
			.list__sub
				display block
			.list__text
				display block
			.list__title
				font-size 4.2rem
				line-height 1.19
				+below($sm)
					font-size 2.8rem
					line-height 1.25
			&::after
				transform rotate(-225deg)
	&__title
		color $b
		transition .3s
		margin-bottom 1.5rem
		+below($sm)
			font-size 2.8rem
			line-height 1.25
	&__text-block
		padding-top 4rem
		pointer-events none
		max-width 58rem
		+below($sm)
			padding-top 6rem
			padding-bottom 3.5rem
	&__image
		pointer-events none
		transition .3s
		+below($sm)
			width 100%
			opacity 0
			transition none
		&-block
			pointer-events none
			position relative
			width 195px
			margin-right 95px
			+below($sm)
				width 90px
				margin-right 0
		&_hover
			position absolute
			opacity 0
			top 0
			left 0
			+below($sm)
				opacity 1
	&__preview
		font-size 2rem
		line-height 1.75
		+below($sm)
			font-size 1.8rem
			line-height 1.67
	&__sub
		font-size 2.8rem
		line-height 1.25
		display none
		padding 6.1rem 0
		+below($sm)
			font-size 1.8rem
			line-height 1.67
			padding 1.5rem 0 4rem
	&__text
		font-size 2rem
		line-height 1.75
		display none
		+below($sm)
			font-size 1.8rem
			line-height 1.67
	&__description
		font-size 2rem
		line-height 1.5
		color $b
		transition .3s

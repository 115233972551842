.slider-services
	display block
	margin-bottom 50px
	&__op,
	&__slider
		display none
		&.slick-initialized
			display block
	&__icon
		max-width 100%

.menu
	transform translateY(-200%)
	position fixed
	display block
	background-color $blue
	width 100%
	height 100vh
	top 0
	left 0
	z-index 99
	transition .4s cubic-bezier(.165, .84, .44, 1)
	+below($sm)
		background-color transparent
		height 100%
		overflow-y scroll
	&_open
		background-color transparent
		transform translateY(0)
		+below($sm)
			padding-top 8rem
	&__wrapper
		width 100%
		height 100%
		display flex
		+below($sm)
			flex-direction column
			height auto
	&__main
		width 62.5%
		+below($sm)
			width 100%
		&_search_active
			.menu__category-list
				height 0
				pointer-events none
			.menu__category-item
				opacity 0
			.menu__list
				display none
			.menu__drawer
				height 100%
			.search__back
				display block
			.search__result
				transition .2s
				opacity 0
				display flex
				&_show
					opacity 1
			.menu__search
				margin-top 8rem
	&__search
		margin 0
		margin-top auto
	&__category
		&-list
			height 320px
			display flex
			background-color $w
			transition height .3s
			+below($sm)
				height auto
				flex-direction column
		&-item
			width 50%
			display flex
			transition opacity .3s
			opacity 1
			+below($sm)
				width 100%
				border-top 2px solid $g
			&:first-child
				border-right 1px solid $g
			&:last-child
				border-left 1px solid $g
		&-link
			width 100%
			text-decoration none
			display flex
			flex-direction column
			padding 11.2rem 25rem 7.2rem 9rem
			+below($sm)
				padding 2.5rem 1.5rem
			&:hover
				.menu__category-title
					color $blue
			&:hover
				.menu__arrow
					stroke $blue
		&-title
			font-size 3.2rem
			line-height 1.25
			font-weight 700
			color $b
			margin-bottom 4rem
			transition .3s
			+below($sm)
				margin-bottom 0
				font-size 2.4rem
	&__arrow
		width 57px
		height 16px
		stroke #e7e7e7
		transition .3s
		+below($sm)
			display none
	&__drawer
		display flex
		flex-direction column
		background-color $blue
		padding 6rem 9rem 8rem
		height calc(100% - 320px)
		transition height .3s
		+below($sm)
			flex-direction column-reverse
			padding 2.8rem 1.5rem 5rem
			height auto
	&__list
		column-count 2
		margin-bottom 4.5rem
		+below($sm)
			margin-bottom 0
			column-count 1
		&-link
			position relative
			font-size 2rem
			line-height 2.5
			font-weight 700
			color $w
			text-decoration none
			+below($sm)
				line-height 2
			&:before
				display none
				width 21px
				height 16px
				position absolute
				content ''
				background-image url(../images/arrow-menu-list.svg)
				left -44px
				top 50%
				transform translateY(-50%)
			&:hover
				&&:before
					display block
	&__close
		position absolute
		top 52px
		right 55px
		+below($sm)
			padding 10px
			top 20px
			right 5px
		&-icon
			stroke $w
			width 40px
			height 40px
			+below($sm)
				width 20px
				height 20px
				stroke $b
.search
	+below($sm)
		margin-bottom 4rem
	&__clear
		background-color transparent
		display none
	&__icon
		width 17px
		height 17px
		&_search
			transition .3s
			stroke $wo
		&_close
			stroke $w
	&__box
		position absolute
		right 0
		top 8px
		&_not-empty
			.search__icon_search
				display none
			.search__clear
				display block
	&__input
		transition .3s
		&::placeholder
			transition .3s
	&__wrapper
		&:hover
			.search__input
				border-color $w
				&::placeholder
					color $w
			.search__icon_search
				stroke $w
	&__result
		margin-top 4.8rem
		display none
		flex-direction column
	&__back
		position relative
		font-size 1.8rem
		line-height 2.78
		color $w
		font-weight 400
		padding-left 26px
		margin-bottom 5.6rem
		display none
		&::before
			position absolute
			content ''
			width 10px
			height 10px
			border-left 2px solid $w
			border-bottom 2px solid $w
			left 0
			top 50%
			transform translateY(-60%) rotate(45deg)
.sub
	flex-grow 1
	padding 14.9rem 5rem 8rem
	background url(../images/sub-bg.svg)
	background-repeat no-repeat
	background-size cover
	display flex
	flex-direction column
	+below($sm)
		padding 4rem 1.5rem
	&__form
		margin 0
		margin-top auto
	&__link
		position relative
		font-size 2.4rem
		line-height 2.08
		text-decoration none
		font-weight 700
		color $w
		&:after
			position absolute
			content ''
			width 10px
			height 10px
			border-right 2px solid $w
			border-bottom 2px solid $w
			right -41px
			top 5px
			transform rotate(-45deg)
		&-container
			margin-bottom 14.9rem
			+below($sm)
				margin-bottom 4rem
	&__label
		display block
		font-size 1.8rem
		font-weight 400
		line-height 1
		color $blue
	&__title
		font-size 2.4rem
		line-height 2.08
		font-weight 700
		color $w
		margin-bottom 2.4rem
	&__input
		margin-bottom 5.5rem
	&__button-wrapper
		text-align right


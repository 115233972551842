.community
	display block
	padding 10rem 0 12rem
	border-top 2px solid $g
	+below($sm)
		padding 6.5rem 0 3.5rem
	&__title
		margin-bottom 1rem
	&__text
		font-size 2.8rem
		line-height 1.25
		margin-bottom 7rem
	&__social
		display flex
		justify-content space-between
		+below($sm)
			flex-wrap wrap
		&-item
			+below($sm)
				width calc(100% / 3)
				text-align center
				margin-bottom 5rem
	&__icon
		width 34px
		height 34px
		fill $b
		transition fill .3s
		&:hover
			fill $blue

.download
	display block
	&__button
		text-decoration none
		display flex
		padding 2rem 6rem
		background-color $blue
		align-items center
		justify-content space-between
		+below($sm)
			padding 2rem 2.5rem
		&:hover
			.download__text
				&:before
					opacity 1
			svg
				&:last-child
					opacity 1
	&__text
		position relative
		font-size 2rem
		line-height 1.75
		font-weight 700
		color $w
		&:before
			position absolute
			content ''
			width 100%
			height 2px
			background-color $w
			left 0
			bottom 0
			opacity 0
			transition .3s
	&__icon-block
		position relative
		svg
			transition .3s
			&:last-child
				position absolute
				left 0
				top 0
				opacity 0

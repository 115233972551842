.text
	display block
	&__title
		&_tarrifs
			max-width 39rem
			margin-bottom 4.8rem
			+below($sm)
				margin-bottom 2.5rem
	&__link
		font-size 3.2rem
		line-height 1.25
		text-decoration none
		color $blue
		+below($sm)
			font-size 2.8rem

import('sprites/*')
import('helpers/svg-size')
@import 'helpers/variables'
@import 'helpers/mixins'
@import 'helpers/fonts'
@import 'helpers/optimize'

.container
	width 100%
	max-width 91rem
	margin 0 auto
	padding 0 1.5rem
.title
	font-size 4.2rem
	line-height 1.19
	font-weight 700
	&_white
		color $w
	+below($sm)
		font-size 4rem
		line-height 1.12
.subtitle
	font-size 3.2rem
	line-height 1.25
	font-weight 700
	+below($sm)
		font-size 2.8rem
.grid
	position relative
	max-width 128rem
	margin 0 auto
	padding 0 5rem
	+below($sm)
		padding 0 1.5rem
button
	background transparent
	border none
	outline none
.input
	width 100%
	background transparent
	border none
	outline none
	font-size 2.4rem
	line-height 1.46
	padding-bottom 1.2rem
	color $w
	border-bottom 2px solid #6CA5FF
	transition .3s
	&::placeholder
		transition .3s
		color #6CA5FF
	&:focus
	&:hover
		border-bottom 2px solid $w
	&_search
		font-size 2rem
	&_textarea
		min-height 166px
		overflow auto
	&-container
		position relative
		width 100%
.button
	&_send
		position relative
		background-color transparent
		padding-left 90px
		font-size 2.4rem
		line-height 1.46
		font-weight 600
		color $w
		text-decoration none
		overflow hidden
		&:before
			position absolute
			content ''
			width 77px
			height 16px
			background-image url(../images/button-arrow.svg)
			background-size contain
			background-repeat no-repeat
			transition .3s
			top 50%
			transform translateY(-50%)
			left -20px
		&:hover
			&&:before
				left 0
	&_reverse
		display block
		padding-left 100px
		&:before
			transform rotate(180deg) translateY(50%)
			left -10px
			&:hover
				&:before
					left -20px
.hidden
	visibility hidden
	position absolute
.overlay
	// width 100vw
	// height 100vh
	// overflow hidden
.top-layout
	position relative
	z-index 500
.unset-padding
	padding 10rem 15rem
	+below($sm)
		padding 7rem 0
.content
	h2
		font-size 2rem
		line-height 1.75
		margin 8rem 0 5rem
		font-weight 700
		+below($sm)
			margin 3.4rem 0 2.1rem
	p
		font-size 2rem
		line-height 1.75
		margin-bottom 3rem
		+below($sm)
			line-height 2.4rem
			margin-bottom 2rem
	img
		padding 4rem 0
	ol
		position relative
		margin 5rem 0 11rem
		counter-reset number
		+below($sm)
			margin 3rem 0 5rem
		li
			font-size 2rem
			line-height 1.75
			padding-left 5rem
			margin-bottom 5.25rem
			&:before
				position absolute
				counter-increment number
				content counter(number)
				left 0
	ul
		margin-right 2.5rem
		margin 5rem 0 11rem
		+below($sm)
			margin 3rem 0 5rem
		li
			position relative
			font-size 1.8rem
			line-height 2.8rem
			padding-left 10.4rem
			margin-bottom 3rem
			&:before
				position absolute
				content ''
				width 6px
				height 6px
				top 11px
				border-radius 50%
				background-color $b
				left 0
			+below($sm)
				padding-left 3.4rem
	i
	em
		font-style italic
	b
	strong
		font-weight 600
	a
		color $blue
.wrapper
	padding-top 120px
import('../blocks/**/*')

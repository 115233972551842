.form
	display block
	background-color $blue
	padding 10.4rem 0 11.2rem
	+below($sm)
		padding 6.7rem 0 7.3rem
	&__container
		position relative
		&_radio
			display flex
			margin-bottom 7rem
			justify-content space-between
	&__question
		font-size 2.4rem
		line-height 1.46
		font-weight 500
		color $w
		width 66%
	&__group
		width 25%
		display flex
		justify-content space-between
	&__title
		margin-bottom 6rem
		max-width 66rem
		+below($sm)
			margin-bottom 2.8rem
			font-size 3.2rem
			line-height 1.25
	&__text
		font-size 2rem
		line-height 1.75
		color $w
		width 80%
		margin-bottom 8rem
	&__label
		position relative
		font-size 2.4rem
		line-height 1.46
		font-weight 500
		color $wo
		padding-left 30px
		cursor pointer
		transition .3s
		&:before
			content ''
			position absolute
			width 21px
			height 15px
			background-image url(../images/check.svg)
			background-repeat no-repeat
			background-size cover
			left 0
			top 0
			opacity 0
			transition .3s
		&:hover
			color $w
	&__input_radio
		position absolute
		z-index -1
		opacity 0
		&:checked
			+ .form__label
				color $w
				&:before
					opacity 1
	&__input
		margin-bottom 7rem
		+below($sm)
			margin-bottom 3.7rem
		&:hover
		&:focus
			border-color $w
			&::placeholder
				color $w
		&_file
			width .1px
			height .1px
			opacity 0
			overflow hidden
			position absolute
			z-index -1
			+ .form__file-label
				display inline-block
				width 100%
				font-size 2.4rem
				line-height 1.46
				color $wo
				font-weight 500
				padding-bottom 1.2rem
				border-bottom 2px solid $wo
				cursor pointer
				transition .3s
				margin-bottom 7rem
				+below($sm)
					margin-bottom 3.7rem
			&:hover
			&:focus
				+ .form__file-label
					border-bottom 2px solid $w
					color $w
				~ .form__icon_add
					stroke $w
	&__icon
		&_add
			position absolute
			width 20px
			height 20px
			stroke $wo
			right 0
			top 8px
			transition .3s
	&__button-wrapper
		&_right
			text-align right
	&__double
		display flex
		width 100%
		justify-content space-between
		+below($sm)
			flex-wrap wrap
		.form__container
			width calc(50% - 10px)
			+below($sm)
				width 100%
	&__half
		width 50%
		+below($sm)
			width 100%
	&__image
		&-block
			position absolute
			right 0
			bottom -12.6rem
			+below($sm)
				display none
	&__container
		&_textarea
			position relative
			min-height 100%
			&:before
				content ''
				position absolute
				width 100%
				height 100%
				left 0
				top 0
				z-index 0
				background repeating-linear-gradient(rgba(0 , 0 , 0, 0), rgba(0, 0, 0, 0) 47px, #ffffff 49px, #ffffff 49px)
			.form__input-pseudo
				max-height 315px
				min-height 147px
				overflow auto
				display block
				position relative
				font-size 2.4rem
				line-height 49px
				color $w
				margin-bottom 7rem
				transition color .3s
				&:before
					content ''
					position absolute
					width 100%
					height 2px
					background-color $wo
					left 0
					bottom 0
				&:after
					color $wo
					content attr(data-placeholder)
				&_focused
					&:after
						color $w
			.input_textarea
				width 100%
				height 100%
				position absolute
				pointer-events none
				opacity 0
		&_radio
			+below($sm)
				flex-direction column
			.form__question
				+below($sm)
					width 100%
					margin-bottom 2rem
			.form__label
				+below($sm)
					padding-left 0
					padding-right 65px
					&:before
						left auto
						right 36px
	&__close
		&-icon
			width 20px
			height 20px
			stroke $w
			position absolute
			right 0
			top -50px



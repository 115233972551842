.link
	display block
	&_deb
		.link__href
			height 50rem
	&_four-element
		.link__item
			width 25%
		.link__href
			height 50rem
	&_adaptive
		+below($sm)
			.link__list
				flex-wrap wrap
			.link__item
				width 100%
			.link__href
				height auto
				padding 7rem 1.5rem 5rem
				border 1px solid $g
			.link__title
				font-size 2.8rem
				line-height 1.25
				margin-bottom 1.4rem
			.link__text
				font-size 2rem
				line-height 1.5
				margin-bottom 4.2rem
			.link__arrow
				stroke $blue
	&__list
		display flex
	&__item
		width calc(100% / 3)
	&__href
		width 100%
		height 42rem
		text-decoration none
		color $b
		display inline-block
		padding 7rem
		border 2px solid $g
		border-left-color transparent
		transition .3s
		+above($sm)
			&:hover
				color $blue
				border-color $blue
				.link__arrow
					stroke $blue
	&__title
		display block
		margin-bottom 1.8rem
	&__block
		height 100%
		display flex
		flex-direction column
	&__arrow
		width 57px
		height 16px
		stroke $dg
		transition stroke .3s
		margin 0
		margin-top auto

.clients
	display block
	&__item
		display flex
		border-top 2px solid $g
		height 130px
		align-items center
		+below($lg)
			flex-wrap wrap
			height auto
			padding 2rem 0
		+below($sm)
			padding 5rem 0
			display block
		&:last-child
			border-bottom 2px solid $g
	&__logo
		flex-grow 0
		flex-shrink 0
		width 130px
		margin-right 20px
		img
			display block
			width 100%
			height auto
			object-fit scale-down
		+below($lg)
			margin-bottom 1.5rem
		+below($sm)
			margin-bottom 1rem
	&__title
		font-size 2rem
		line-height 1.75
		font-weight 700
		width 40%
		margin-right 2rem
		+below($lg)
			width 50%
			margin-right 0
			text-align left
			order 3
		+below($sm)
			margin-bottom 0
			width 100%
	&__information
		display flex
		flex-direction column
		color $dg
		line-height 2.5
		margin-right 75px
		+below($lg)
			width 50%
			flex-direction row
			margin-right 0
			order 2
			margin-left auto
			justify-content flex-end
		+below($sm)
			width 100%
			margin-left 0
			justify-content flex-start
			margin-bottom 3.2rem
	&__num-block
		display flex
		flex-grow 1
		justify-content space-between
		+below($lg)
			order 5
		+below($sm)
			justify-content flex-start
	&__cost
		font-size 2.8rem
		line-height 1.25
		width 10rem
		+below($lg)
			margin-left auto
			order 4
		+below($sm)
			position relative
			width 9rem
			margin-left 0
			&:after
				position absolute
				content ''
				width 2px
				height 100%
				background-color $g
				right 0
				top -2px
	&__percent
		font-size 2.8rem
		line-height 1.25
		+below($lg)
			flex 0 0 12rem
			order 5
			text-align right
			margin-left 0
		+below($sm)
			flex 0 0 0
			margin-left 3rem
	&__number
		+below($lg)
			flex 0 0 12rem
			text-align right
		+below($sm)
			flex 0 0 auto
			padding-left 1rem
			text-align left

.footer
	display block
	&__top
		padding 9rem 0 8rem
		background $bgrad
		+below($sm)
			padding 6.5rem 0 0
		&-wrapper
			display flex
			+below($sm)
				flex-direction column
	&__bottom
		padding 4.2rem 0
		background-color #001331
		+below($sm)
			padding 3.5rem 0 2.5rem
		&-wrapper
			display flex
			justify-content space-between
			+below($sm)
				align-items center
				flex-direction column
	&__email
		width 50%
		padding-right 5rem
		+below($lg)
			padding-right 2rem
		+below($sm)
			width 100%
			padding-right 0
	&__list
		width 50%
		column-count 2
		padding-left 7rem
		+below($lg)
			padding-left 2rem
		+below($sm)
			padding-top 3.5rem
			padding-bottom 3.5rem
			width 100%
			padding-left 0
			column-count 1
		&-link
			text-decoration none
			font-size 1.8rem
			line-height 2.22
			font-weight 700
			color $lb
			transition .3s
			&:hover
				color $w
	&__social
		margin 0 -1.5rem
		display flex
		&-link
			display inline-block
	&__icon
		margin 0 1.5rem
		width 20px
		height 20px
		fill $lb
		transition fill .3s
		&:hover
			fill $w
	&__develop
		font-size 1.8rem
		line-height 2.2
		font-weight 300
		color #68A2FF
	&__text
		padding-right 4px
		color #68A2FF
.email
	&__title
		font-size 3.2rem
		line-height 1.25
		font-weight 700
		color $w
		margin-bottom 4rem
	&__form
		display flex
		flex-direction column
	&__input
		background-color transparent
		border none
		outline none
		font-size 2.4rem
		line-height 1.46
		color $w
		border-bottom 2px solid #829BC4
		margin-bottom 5rem
		transition border .3s
		&::placeholder
			transition .3s
			color #829BC4
		&:focus
		&:hover
			border-bottom 2px solid $w
			&::placeholder
				color $w
	&__button
		display flex
		align-items center
		background-color transparent
		border none
		font-size 2.4rem
		line-height 1.46
		color $w
	&__arrow
		margin-right 2rem

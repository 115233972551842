.linkimage
	display block
	&__list
		display flex
		flex-wrap wrap
	&__item
		width calc(100% / 3)
		+below($sm)
			width 100%
	&__href
		width 100%
		text-decoration none
		color $b
		display inline-block
		border 1px solid $g
		transition .3s
		+below($sm)
			border none
		&:hover
			color $blue
			border-color $blue
	&__title
		display block
		margin-bottom 1.8rem
		+below($sm)
			font-size 2rem
			line-height 1.5
			margin-bottom 1.5rem
	&__block
		height 100%
		display flex
		flex-direction column-reverse
	&__text-block
		min-height 20rem
		padding 4rem 5rem
		+below($sm)
			height auto
			padding 2.8rem 1.5rem 8.2rem
	&__text
		font-size 2rem
		line-height 1.5
	&__image
		width 100%
		height 100%
		line-height 0
		img
			width 100%
			height auto
			object-fit cover

.selectcat
	display block
	+below($sm)
		background $blue
		border-radius 15px
		margin 0 .5rem
	&_mb10
		margin-bottom 10rem
	&__wrapper
		display flex
		justify-content space-between
		+below($sm)
			flex-direction column-reverse
	&__item
		position relative
		width calc(50% - 8px)
		border-radius 36px
		border 3px solid #F5F5F5
		padding 7rem 5.6rem
		color $b
		text-decoration none
		transition .3s
		background-color $w
		+below($sm)
			width 100%
			background-color transparent
			border none
			padding 5rem 1rem
			border-radius 0
			&:last-child
				border-bottom 2px solid #8DB9FF
		&:before
			position absolute
			content ''
			width 80%
			height 40px
			background-color $blue
			bottom -20px
			z-index -1
			opacity 0
			filter blur(30px)
			left 50%
			transform translateX(-50%)
			transition .3s
		&:hover
			background-color $blue
			color $w
			border-color $blue
			.selectcat__image
				opacity 0
				&_hover
					opacity 1
			&:before
				opacity .7
	&__image
		transition .3s
		+below($sm)
			transition none
			opacity 0
	&__image-block
		position relative
		margin-bottom 7rem
		+below($sm)
			width 8.4rem
			margin-bottom 2.6rem
			img
				width 100%
	&__image_hover
		left 0
		top 0
		position absolute
		opacity 0
		+below($sm)
			opacity 1
	&__title
		margin-bottom 8px
		+below($sm)
			font-size 2.8rem
			line-height 1.25
			color $w
			margin-bottom 0
	&__text-value
		font-size 2rem
		line-height 1.5
		+below($sm)
			font-size 1.8rem
			line-height 1.39
			color $w



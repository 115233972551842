.fill
	display block
	border-bottom 2px solid $g
	margin-bottom -2px
	&_without-border
		border-color transparent
	&__wrapper
		position relative
	&__back
		position absolute
		display inline-block
		padding 3rem 2rem
		left -2rem
		top 8.5rem
		+below($sm)
			display none
		&:hover
			.fill__arrow
				stroke $blue
	&__arrow
		width 57px
		height 16px
		stroke $dg
		transform rotate(180deg)
		transition .3s
	&__title
		margin-bottom 5rem
		+below($sm)
			font-size 2.8rem
			line-height 1.25
			margin-bottom 3rem

